import React, { Component, Fragment } from "react";
import _ from 'lodash';
import {ImageInfoView} from "../../../components/Images/ImageInfoView";
import deviceViewContentStyle from './deviceViewContentStyle.jsx';
import withStyles from "@material-ui/core/styles/withStyles";
import Button from '@material-ui/core/Button';
import ft from 'firebase-timestamp-utils';

const getDeviceImageSrc = (platform) => {
  // Default
  const imageSrc = 'https://lh3.googleusercontent.com/iO7DlCJxFShjGiCmNYv9zrKMr0G_G0HAYydfD_Ix9257zmuhHItW8Rs5PNCwg7gZ1IAguQUAp_82PA=s72';

  // 'iPhone SE' = // https://www.gstatic.com/identity/boq/accountsettingssecuritycommon/images/sprites/devices_default-6ff8cdb….png

  return imageSrc;
};

class DeviceView extends Component {
  constructor(props) {
    super(props);

    this.onClick = this.onClick.bind(this);
  }

  onClick() {
    const { onDeleteClick, device } = this.props;
    const { key } = device;

    if (onDeleteClick) {
      onDeleteClick(key)
    }
  }

  render() {
    const {
      classes,
      device = {},
      onClick,
    } = this.props;
    const {
      deviceName,
      lastAccessedAt,
      platform,
    } = device;
    const imageSrc = getDeviceImageSrc(platform);
    const formattedLastAccessed = ft.getFormattedTimeFromNow(lastAccessedAt);

    return (
      <div className={classes.container}>
        {/*{icon !== undefined ? <props.icon className={classes.icon} /> : null}*/}
        <div className={classes.imageContainer}>
          <img src={imageSrc} alt="..." width={52} height={52} />
        </div>
        <div className={classes.content}>
          <h4 className={classes.deviceName}>
            {deviceName}
          </h4>
          <div className={classes.lastAccessed}>
            {`Last accessed: ${formattedLastAccessed} ago`}
          </div>
        </div>
        <div className={classes.actionButtons}>
          <Button
            className={ classes.deleteBtn }
            size="large"
            onClick={this.onClick}
          >
            Remove
          </Button>
        </div>
      </div>
    )
  }
}

export default withStyles(deviceViewContentStyle)(DeviceView);