import {
  defaultFont,
  primaryBoxShadow,
  infoBoxShadow,
  successBoxShadow,
  warningBoxShadow,
  dangerBoxShadow,
  roseBoxShadow,
  whiteColor,
  blackColor,
  grayColor,
  infoColor,
  successColor,
  dangerColor,
  roseColor,
  primaryColor,
  warningColor,
  hexToRgb,
} from '../../../assets/jss/material-dashboard-react.jsx';

const snackbarContentStyle = {
  root: {
    ...defaultFont,
    flexWrap: 'unset',
    position: 'relative',
    lineHeight: '20px',
    marginBottom: '10px',
    fontSize: '14px',
    backgroundColor: whiteColor,
    color: grayColor[7],
    borderRadius: '15px',
    minWidth: 'unset',
    maxWidth: 'unset',
    padding: '0 20px',
    boxShadow:
      `0 0 0 0 rgba(${
        hexToRgb(whiteColor)
        }, 0.28), 0 2px 2px 0px rgba(${
        hexToRgb(blackColor)
        }, 0.12), 0 2px 2px -2px rgba(${
        hexToRgb(whiteColor)
        }, 0.2)`,

  },
  container: {
    top: '20px',
    display: 'flex',
    height: '56px',
    // width: '100%',
    backgroundColor: 'white',
    padding: '20px',
    borderColor: grayColor[4] + ' !important',
    borderWidth: '1px !important',
    borderStyle: 'solid',
    width: 500,
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  imageContainer: {
    marginRight: '18px',
  },
  actionButtons: {
    // justifyContent: 'flex-end',
    flex: 1,
    justifyContent: 'space-between',
  },
  deleteBtn: {
    // display: 'flex',
    border: '1px solid #999',
    textTransform: 'none',
    // flex: 1,
    // justifyContent: 'flex-end',
    // marginLeft: 'auto',
    // marginRight: '0',
    // position: 'absolute',
    // right: '0'
    float: 'right',
    marginTop: '5px',
    marginRight: '10px',
  },
  deviceName: {
    margin: '0',
    padding: '0',

  },
  lastAccessed: {
    margin: '0',
    padding: '0',
  },

  // top40: {
  //   top: '40px',
  // },
  // info: {
  //   backgroundColor: infoColor[3],
  //   color: whiteColor,
  //   // ...infoBoxShadow,
  // },
  // success: {
  //   backgroundColor: successColor[3],
  //   color: whiteColor,
  //   // ...successBoxShadow,
  // },
  // warning: {
  //   backgroundColor: warningColor[3],
  //   color: whiteColor,
  //   // ...warningBoxShadow,
  // },
  // danger: {
  //   backgroundColor: dangerColor[3],
  //   color: whiteColor,
  //   // ...dangerBoxShadow,
  // },
  // primary: {
  //   backgroundColor: primaryColor[3],
  //   color: whiteColor,
  //   // ...primaryBoxShadow,
  // },
  // rose: {
  //   backgroundColor: roseColor[3],
  //   color: whiteColor,
  //   // ...roseBoxShadow,
  // },
  // message: {
  //   padding: '0',
  //   display: 'block',
  //   maxWidth: '89%',
  // },
  // close: {
  //   width: '22px',
  //   height: '22px',
  // },
  // mainBLock: {
  //   borderRadius: 15,
  // },
  // iconButton: {
  //   width: '48px',
  //   height: '48px',
  //   padding: '0px',
  // },
  // icon: {
  //   display: 'block',
  //   left: '15px',
  //   position: 'absolute',
  //   top: '50%',
  //   marginTop: '-15px',
  //   width: '30px',
  //   height: '30px',
  // },
  // infoIcon: {
  //   color: infoColor[3],
  // },
  // successIcon: {
  //   color: successColor[3],
  // },
  // warningIcon: {
  //   color: warningColor[3],
  // },
  // dangerIcon: {
  //   color: dangerColor[3],
  // },
  // primaryIcon: {
  //   color: primaryColor[3],
  // },
  // roseIcon: {
  //   color: roseColor[3],
  // },
  // iconMessage: {
  //   paddingLeft: '50px',
  //   display: 'block',
  // },
  // actionRTL: {
  //   marginLeft: '-8px',
  //   marginRight: 'auto',
  // },
  // selected: {
  //   backgroundColor: '#c5f2f7',
  // }
};

export default snackbarContentStyle;
