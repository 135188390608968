import _ from "lodash";
import { toTitleCase } from './stringUtils';

const getPrimaryDevice = ({ devices, primaryDeviceId }) => {
  primaryDeviceId = primaryDeviceId || Object.keys(devices)[0];
  const primaryDevice = devices[primaryDeviceId];

  return primaryDevice;
};

// export const getDeviceName = ({ devices, primaryDeviceId }) => {
export const getDeviceName = (user = {}) => {
  if (_.isEmpty(user.devices)) {
    return 'Unknown';
  }

  const { deviceName, isDevice } = getPrimaryDevice(user);
  let result = deviceName;
  if (isDevice === false) {
    result += ' (emulator)';
  }

  return result;
};

export const getDeviceManufacturer = (user = {}) => {
  if (!_.isEmpty(user.devices)) {
    const { deviceDetails = {}, deviceName, manufacturer } = getPrimaryDevice(user);
    if (deviceDetails.brand) return deviceDetails.brand;
    if (manufacturer) return toTitleCase(manufacturer);
    if (getDeviceOS(user) === 'ios') return 'Apple';
    if (deviceName.startsWith('CPH')) return 'Oppo';
    if (deviceName.startsWith('SM-') || deviceName.startsWith('GT-')) return 'Samsung';
    if (deviceName.startsWith('Redmi ') ||
        deviceName.startsWith('MI ') ||
        deviceName.startsWith('Mi ') ||
        deviceName.startsWith('POCOPHONE ')) return 'Xiaomi';
    if (deviceName.startsWith('vivo ')) return 'Vivo';
    if (deviceName.startsWith('Nokia ')) return 'Nokia';
    if (deviceName.startsWith('moto ')) return 'Motorola';
    if (deviceName.startsWith('B3-')) return 'Acer';
    if (deviceName.startsWith('ASUS_')) return 'Asus';
    if (deviceName.startsWith('PCT-') ||
        deviceName.startsWith('JSN-') ||
        deviceName.startsWith('DUK-') ||
        deviceName.startsWith('PLK-')) return 'Honor';

    if (deviceName.startsWith('ONEPLUS ')) return 'OnePlus';
    if (deviceName.startsWith('Neffos_')) return 'Neffos';

    if (deviceName.startsWith('Pixel ')) return 'Google';
    if (deviceName.startsWith('HUAWEI ')) return 'Huawei';
    if (deviceName.startsWith('RMX')) return 'Realme';
    // if (deviceName.startsWith('PAR-') ||
    //     deviceName.startsWith('BLA-') ||
    //     deviceName.startsWith('CLT-') ||
    //     deviceName.startsWith('COL-') ||
    //     deviceName.startsWith('DUB-') ||
    //     deviceName.startsWith('EVR-') ||
    //     deviceName.startsWith('INE-') ||
    //     deviceName.startsWith('JKM-') ||
    //     deviceName.startsWith('LDN-') ||
    //     deviceName.startsWith('LYA-') ||
    //     deviceName.startsWith('MAR-') ||
    //     deviceName.startsWith('RNE-') ||
    //     deviceName.startsWith('STK-') ||
    //     deviceName.startsWith('SHT-') ||
    //     deviceName.startsWith('VOG-') ||
    //     deviceName.startsWith('VTR-') ||
    //
    //     deviceName.startsWith('HUAWEI ') ||
    //     deviceName.startsWith('WAS-')) return 'Huawei';
    const regex = /[A-Z]{3}-/;
    if (deviceName.match(regex)) return 'Huawei';
  }

  return 'Unknown';
};

export const getDeviceOS = (user = {}) => {
  if (_.isEmpty(user.devices)) {
    return 'Unknown';
  }

  const { platform } = getPrimaryDevice(user);
  return Object.keys(platform)[0];
};