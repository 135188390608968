import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@material-ui/core/styles/withStyles';
import Select, { Creatable } from 'react-select';
import makeAnimated from 'react-select/lib/animated';
import style from '../../../../assets/jss/material-dashboard-react/components/details';


const stylesForMainPage = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 'inherit',
  }),
};

const stylesForElement = {
  control: base => ({
    ...base,
    minHeight: 32,
    width: 'inherit',
    maxWidth: 400,
    minWidth: 200,
  }),
};

class MultiSelect extends React.Component {
  onChange = (newValue) => {
    const {
      onChange,
      targetName,
      targetStorage,
      id,
    } = this.props;
    const { options } = this.props;

    onChange({
      [targetName]: newValue,
      [targetStorage]: options,
    }, {
      key: id,
      value: newValue.map(x => x.value)
    });
  };

  render() {
    const {
      value,
      options,
      name,
      description,
      classes,
      flex,
      creatable,
      isMainPage,
    } = this.props;
    const params = {
      styles: isMainPage ? stylesForMainPage : stylesForElement,
      maxMenuHeight: 140,
      closeMenuOnSelect: false,
      components: makeAnimated(),
      value,
      options,
      onChange: this.onChange,
      isMulti: true,
    };

    return (
      <div className={flex ? classes.headerForRowSelect : ''}>
        <div className={classes.textContainer}>
          <label className={classes.labelInput} style={{ paddingBottom: 7, minWidth: 160, width: 200}}> {name}</label>
          {description && <label className={classes.labelDesc}> {description}</label>}
        </div>
        <div className={classes.select}>
        {creatable ? <Creatable {...params} isClearable /> : <Select {...params} />}
        </div>
      </div>
    );
  }
}

MultiSelect.propTypes = {
  classes: PropTypes.object.isRequired,
  value: PropTypes.array,
  options: PropTypes.array,
  onChange: PropTypes.func,
  targetName: PropTypes.string,
  targetStorage: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  flex: PropTypes.bool,
  creatable: PropTypes.bool,
  isMainPage: PropTypes.bool,
};

MultiSelect.defaultProps = {
  value: [],
  options: [],
  onChange: () => null,
  targetName: 'category',
  targetStorage: 'categories',
  name: 'Category',
  description: '',
  flex: true,
  creatable: false,
  isMainPage: false,
};


export default withStyles(style)(MultiSelect);
