import _ from 'lodash';
import ft from "firebase-timestamp-utils";

const defaultAvatarUrl = 'https://www.macritchie.io/images/default_avatar.jpg';

export const getRegistrationMethod = ({ google, facebook, apple, phone }) => {
  if (!_.isEmpty(google)) return 'Google';
  if (!_.isEmpty(facebook)) return 'Facebook';
  if (!_.isEmpty(phone)) return 'Phone';
  if (!_.isEmpty(apple)) return 'Apple';
  return 'None';
};

const getPhoneNumber = (phone) => {
  const { callingCode, phoneNumber } = phone;
  return `(+${callingCode}) ${phoneNumber}`;
};

const getUnknownUserSummary = () => {
  return {
    name : 'Unknown User',
    photoUrl: defaultAvatarUrl,
  }
};

const getGooglePhotoUrl = ({ photoURL, photoUrl }) => photoUrl || photoURL;

export const getGroupsText = ({ groups = {} }) => {
  let results = [];
  const entries = Object.entries(groups);

  for (const [key, value] of entries) {
    if (value === true) {
      results.push(key.substring(0, 1).toUpperCase())
    }
  }

  return results.length ? results.join('/') : undefined;
};

export const getUserSummaryGoogle = (google) => {
  const { displayName, name, email } = google;
  const photoUrl = getGooglePhotoUrl(google);
  return {
    name: name || displayName || 'Unknown Customer',
    email,
    photoUrl,
  }
};

const getUserSummaryFacebook = (facebook) => {
  // console.log('getUserSummaryFacebook');
  const { name, id, email } = facebook;
  const photoUrl = `http://graph.facebook.com/${id}/picture?type=large&redirect=true&width=500&height=500`; // _.get(facebook, 'picture.data.url');

  return {
    name,
    email,
    photoUrl,
  }
};

const getUserSummaryPhone = (phone) => {
  const name = getPhoneNumber(phone);
  return {
    name,
    photoUrl: defaultAvatarUrl,
  }
};

const getUserSummaryApple = apple => {
  const { email, fullName } = apple;
  const { givenName, familyName } = fullName;
  const name = `${givenName} ${familyName}`;
  return {
    name,
    photoUrl: defaultAvatarUrl,
    email,
  }
};

export const getSocialLoginType = (user) => {
  const { social = {} } = user;
  const { facebook, google, phone } = social;

  if (!_.isEmpty(facebook)) {
    return 'Facebook';
  }
  else if (!_.isEmpty(google)) {
    return 'Google';
  }
  else if (!_.isEmpty(phone)) {
    return 'your account';
  }
};

export const getUserSummary = (user) => {
  // If this user is actually a user summary return
  if (user.name) {
    return user;
  }

  let userSummary;
  // console.log('getUserSummary():', JSON.stringify(user));
  const { social = {} } = user;
  const { facebook, google, apple, phone } = social;

  if (!_.isEmpty(facebook)) {
    userSummary = getUserSummaryFacebook(facebook);
  }
  else if (!_.isEmpty(google)) {
    userSummary = getUserSummaryGoogle(google);
  }
  else if (!_.isEmpty(phone)) {
    userSummary = getUserSummaryPhone(phone);
  }
  else if (!_.isEmpty(apple)) {
    userSummary = getUserSummaryApple(apple);
  }
  else {
    userSummary = getUnknownUserSummary();
  }

  return userSummary;
};

export const getFormattedLastAccessedTime = (lastAccessedAt) => {
  if (!lastAccessedAt) return 'Just now';
  let formattedLastAccessed = ft.getFormattedTimeFromNow(lastAccessedAt);
  return (formattedLastAccessed) ? `${formattedLastAccessed.replace('-', '')} ago` : 'Unknown';
};

// This belongs in FT utils module
export const getMostRecent = (a, b) => {
  if (!a || !a.seconds) return b;
  if (!b || !b.seconds) return a;
  const { seconds: secondsA } = a;
  const { seconds: secondsB } = b;
  const max = Math.max(secondsA, secondsB);
  return secondsA === max ? a : b;
};